import React, { useState, useRef, useEffect } from "react"
import { Element, Link } from "react-scroll"

// components
import WaitingListModal from "./WaitingListModal"

// images
import logo from './svg/logo.svg'
import poster from './svg/poster.svg'

export default function Landing() {
  const [isWaitingListOpen, setIsWaitingListOpen] = useState(false)

  const openWaitingListModal = () => setIsWaitingListOpen(true)
  const closeWaitingListModal = () => setIsWaitingListOpen(false)

  function Hero() {
    const [scrollPos, setScrollPos] = useState(0)

    useEffect(() => {
      // Scroll handler
      const handleScroll = () => {
        setScrollPos(window.scrollY)
      }
  
      window.addEventListener("scroll", handleScroll, { passive: true })
      return () => window.removeEventListener("scroll", handleScroll)
    }, [])
  
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noreferrer")
    }
  
    const homeSectionStyle = {
      height: "100vh",
      width: "100%",
    }
    
    return (
      <Element name="hero">
        <div className='container' style={homeSectionStyle}>
          <div>
            <span className='top-left-name'>
              AROUND
            </span>
            <Link className='floating-logo' to="hero" smooth={true} duration={500}>
              <img alt="Logo" 
                src={logo} 
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </Link>
          </div>

          <div className="text-container" style={{transform: `translateY(-${scrollPos * 0.2}px)`}}>
            <span style={{fontWeight: 600, marginBottom: '30px'}}>DIV-E SEPID<br />MARCH 22 | 9PM to 3AM</span>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <span style={{marginBottom: '20px'}}>
                INSPIRED BY PERSIAN MYTHOLOGY, WE INVOKE "DIV-E SEPID," THE LEGENDARY "WHITE DEMON" OF WINTER, TO MARK THE TURNING OF SEASONS.
              </span>
              <span style={{marginBottom: '20px'}}>
                JOIN US FOR AN INTIMATE GATHERING THAT
                CELEBRATES THE RHYTHM OF CONNECTION AND
                THE POWER OF COMMUNITY. EXPECT AN EVENING
                OF MUSIC, CONVERSATION, AND INSPIRATION IN A
                SPACE CURATED TO BRING PEOPLE TOGETHER.
              </span>
              <span style={{marginBottom: '20px'}}>
                WHETHER YOU COME TO DANCE, TO CONNECT, OR
                SIMPLY TO EXPERIENCE SOMETHING
                EXTRAORDINARY, THIS IS YOUR INVITATION TO BE
                PART OF AROUND'S FIRST CHAPTER.
              </span>
            </div>
            <button className='waiting-list-button flex-center' onClick={() => openInNewTab('https://ra.co/events/2099523')}>
              <span style={{margin: 0}}>TICKETS</span>
            </button>
          </div>

          <div className="image-container flex-center">
            <img alt="poster" src={poster} />
          </div>

        </div>
      </Element>
    )
  }

  function About() {
    const [scrollPos, setScrollPos] = useState(0)
    const sectionRef = useRef(null)
  
    useEffect(() => {
      // Scroll handler
      const handleScroll = () => {
        setScrollPos(window.scrollY)
      }
  
      window.addEventListener("scroll", handleScroll, { passive: true })
      return () => window.removeEventListener("scroll", handleScroll)
    }, [])
  

    const linkStyle = {
      color: 'white'
    }

    return (
      <Element name="about">
        <div className='about' ref={sectionRef}>
          <div className='container second-section'>
            <div />
            <div className="overflow-middle" style={{transform: `translateY(-${scrollPos * 0.4}px)`}}>
              <span style={{fontWeight: 600, marginBottom: '20px'}}>AROUND</span>
              <br /><br />
              <span>
                AROUND IS A COLLECTIVE INSPIRED BY THE RHYTHM OF CONNECTION, BLENDING
                CULTURE, CREATIVITY, AND COMMUNITY. THROUGH THOUGHTFULLY CURATED EVENTS,
                WE CREATE SPACES TO GATHER, INSPIRE, AND CELEBRATE SHARED EXPERIENCES.<br />
                MORE THAN JUST A MOMENT—IT'S WHERE STORIES BEGIN.
              </span>
              <br /><br />
              <button className='waiting-list-button flex-center' onClick={openWaitingListModal}>
                <span style={{margin: 0}}>STAY CONNECTED</span>
              </button>
            </div>
            <div />
          </div>

          <div>
            <div style={{position: 'absolute', left: '5%', bottom: '10%', width: '70%'}}>
              <span className='bigger-text'>
                HAVE AN IDEA?<br/>
                WANT TO COLLABORATE?<br/>
                <a style={linkStyle} href="mailto:info@aroundtoronto.ca">LET’S TALK</a>
              </span>
            </div>
            
            <div style={{position: 'absolute', right: '5%', bottom: '10%'}}>
              <span>
                <a style={linkStyle} href="https://www.instagram.com/aroundtorontooo/" target="_blank" rel="noopener noreferrer">
                  INSTAGRAM
                </a>
              </span>
            </div>
          </div>
        </div>
      </Element>
    )
  }

  return (
    <div>
      <WaitingListModal isOpen={isWaitingListOpen} onClose={closeWaitingListModal} />
      <Hero />
      <About />
    </div>
  )
}
