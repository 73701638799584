import React, { useState } from "react"

function WaitingListModal({ isOpen, onClose }) {
  const [submissionMessage, setSubmissionMessage] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (!isOpen) {
    return null
  }

  let color = '#a9a9a9b0'

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    const form = e.target

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(new FormData(form)).toString(),
    })
      .then(() => {
        setSubmissionMessage("Thanks for staying connected! You'll be the first to hear about our upcoming events—stay tuned.")
        // Automatically close the modal after 2 seconds
        // setTimeout(() => {
        //   onClose()
        // }, 2000)
      })
      .catch((error) => {
        setSubmissionMessage("Oops! Something went wrong.")
        console.error(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }
  
  function CloseButton() {
    return (
      <button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          backgroundColor: "transparent",
          border: "none",
          color: "#007BFF",
          cursor: "pointer",
        }}
      >
        <svg
          className="close-button-svg"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <line
            x1="6"
            y1="6"
            x2="18"
            y2="18"
            stroke={color}
            stroke-width="1"
            stroke-linecap="round"
          />
          <line
            x1="6"
            y1="18"
            x2="18"
            y2="6"
            stroke={color}
            stroke-width="1"
            stroke-linecap="round"
          />
        </svg>
      </button>
    )
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999
      }}
    >
      <div
        className="modal-container"
        style={{
          backgroundColor: "black",
          borderRadius: "30px",
          border: "1px solid white",
          textAlign: "center",
        }}
      >
        <CloseButton />
        
        {submissionMessage ? 
        <span style={{ margin: "20px 0px", color: "#fff" }}>{submissionMessage}</span> : 
        <>
          <span style={{margin: '20px 0px'}}>BE <span style={{fontWeight: 600}}>AROUND</span> FOR WHAT'S NEXT</span>
          <form
            name="waiting-list"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {/* Hidden field required for Netlify */}
            <input type="hidden" name="form-name" value="waiting-list" />
            <input
              type="email"
              name="email"
              placeholder="ENTER YOUR EMAIL"
              required
              className="styled-input"
              style={{
                borderRadius: "13px",
                backgroundColor: "transparent",
                border: "1px solid " + color,
              }}
            />
            <button
              type="submit"
              className="modal-register-button"
              style={{
                fontFamily: "Bai Jamjuree, serif",
                fontWeight: 500,
                borderRadius: "13px",
                backgroundColor: "transparent",
                border: "1px solid " + color,
                cursor: "pointer",
                color: color,
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registering..." : "REGISTER"}
            </button>
          </form>
        </>}
      </div>
    </div>
  )
}

export default WaitingListModal
