// react router
import { Route, Routes } from "react-router-dom"

// components
import Landing from './Landing.js'
import PageNotFound from './PageNotFound.js'

export default function App() {

  return (
  <div id="app">
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="/" element={<Landing />} />
      </Routes>
  </div>
  )
}
