import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'

import { BrowserRouter } from "react-router-dom"

// components
import App from './App.js'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <div id="index">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </div>
)
