import { useNavigate } from "react-router-dom"
import logo from './svg/logo.svg'

export default function PageNotFound() {
  const navigate = useNavigate()

  return (
    <div
      className='page-not-found'
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <button
        onClick={() => navigate('/')}
        style={{
          width: '80vw',
          height: '80vh',
          cursor: 'pointer',
          background: 'none',
          border: 'none',
          padding: 0,
        }}
      >
        {/* The logo scales to fit the screen (while maintaining aspect ratio) */}
        <img
          src={logo}
          alt="Logo"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      </button>
      <span>page not found</span>
    </div>
  )
}
